import React       from 'react';
import PropTypes   from 'prop-types';
import './HomepageBlock.scss';
import rectangle   from '../../../images/yellow-rectangle.svg';
import businessman   from '../../../images/businessman-03 1.svg';
import handshake   from '../../../images/shaking-hands.svg';
import headset   from '../../../images/headset 1.svg';
import iconDown    from '../../../images/icon_down.svg';
import appleBtn    from '../../../images/badge-apple_store.svg';
import googleBtn   from '../../../images/badge-google_play.svg';
import Carousel    from '../../_ui/Carousel/Carousel';
import { useIntl } from 'gatsby-plugin-intl';
import scrollTo from 'gatsby-plugin-smoothscroll';

const HomepageBlock = ({ customId, mode, headline, title, description, descriptions, bulletList, cta, images, quotes, hasBg, isAgent }) => {
  const intl = useIntl();
  const handshakeIcons = [businessman, handshake, headset];

  // mode: can be 'cta', 'default' 'reverse', 'big', 'cta-handshakes', 'partners', 'quotes'
  const _title = <h1 dangerouslySetInnerHTML={{ __html: title }}/>;
  const _description = description && <h4 className="homepage-block__description-1"
                           dangerouslySetInnerHTML={{ __html: description[0] }}/>;
  const _description1 = descriptions && descriptions.length && (
    <div className="homepage-block--big__description description-1">
      <h4>{descriptions[0].title}</h4>
      <p>{descriptions[0].text}</p>
    </div>
  );
  const _description2 = descriptions && descriptions.length && descriptions.length > 1 && (
    <div className="homepage-block--big__description description-2">
      <h4>{descriptions[1].title}</h4>
      <p>{descriptions[1].text}</p>
    </div>
  );
  const _description3 = descriptions && descriptions.length && descriptions.length > 2 && (
    <div className="homepage-block--big__description description-3">
      <h4>{descriptions[2].title}</h4>
      <p>{descriptions[2].text}</p>
    </div>
  );
  const _description4 = descriptions && descriptions.length && descriptions.length > 3 &&(
    <div className="homepage-block--big__description description-4">
      <h4>{descriptions[3].title}</h4>
      <p>{descriptions[3].text}</p>
    </div>
  );
  const _description5 = descriptions && descriptions.length && descriptions.length > 4 && (
    <div className="homepage-block--big__description description-5">
      <h4>{descriptions[4].title}</h4>
      <p>{descriptions[4].text}</p>
    </div>
  );
  const _cta = cta ? <button className="ab-button ab-button-big" onClick={cta.action}>{cta.text}</button> : <></>;
  const _ctaSecondary = cta ? <button className="ab-button ab-button-medium"
                                      onClick={cta.action}>{cta.text}</button> : <></>;
  const _image = images && <div className="homepage-block__image"><img src={images[0]} alt="iphone"/></div>;
  const _imageMobile = images && images.length > 1 && <div className="homepage-block__image--mobile"><img src={images[1]} alt="iphone" /></div>;
  const _scrollDown = <div className="homepage-block__scroll"><img className="cursor-pointer" src={iconDown} onClick={() => scrollTo(isAgent ? '#homepage-agents-block-2' : '#products')} alt="scroll down"/></div>;
  const _googleBtns = (
    <div className="homepage-block__google-btns">
      <p>{isAgent ? intl.formatMessage({ id: 'homepage-agents.available-in' }): intl.formatMessage({ id: 'homepage.available-in' })}</p>
      <img className="cursor-pointer" src={appleBtn} alt="download btn" onClick={() => window.open('https://apps.apple.com/it/app/assibro/id1482279928', 'blank')}/>
      <img className="cursor-pointer" src={googleBtn} alt="download btn" onClick={() => window.open('https://play.google.com/store/apps/details?id=com.assibro.app', 'blank')}/>
    </div>
  );

  const _headline = <h5>{headline}</h5>;
  const _bulletList = bulletList && bulletList.length ?
                      (<div className="homepage-block__ul">
                        {bulletList.map((text, i) => (
                          <div key={i}><img src={rectangle} alt="ul"/> <p>{text}</p></div>
                        ))}
                      </div>) : <></>;
  const _handshakeList = bulletList && bulletList.length ?
                         (<div className="homepage-block__handshakes">
                           {bulletList.map((caption, i) => (
                             <div key={i}>
                               <div className="homepage-block__handshakes__handshake">
                                 <img src={handshakeIcons[i]} alt="handshake"/>
                               </div>
                               <h4>{caption.title}</h4>
                               <p>{caption.text}</p>
                             </div>
                           ))}
                         </div>) : <></>;
  const _partners = images && images.length ?
                    (<div className="homepage-block__partners-gallery">
                      {images.map((image, i) => (
                        <div key={i} style={{ backgroundImage: `url(${image})` }}/>
                      ))}
                    </div>) : <></>;
  const _quotes = quotes && quotes.length ?
                  (<Carousel quotes={quotes}/>) : <></>;

  if (mode === 'cta') {
    return (<div id={customId} className={isAgent ? 'homepage-block homepage-block--cta homepage-block homepage-block--cta--is-agent' : 'homepage-block homepage-block--cta'}>
      <div className="homepage-block__text">
        {_title}
        {_description}
        {_cta}
      </div>
      {_googleBtns}
      {_image}
      {_scrollDown}
    </div>);
  } else if (mode === 'default') {
    return (<div id={customId} className="homepage-block homepage-block--default">
      {_image}
      <div className="homepage-block__text">
        {_headline}
        {_title}
        {_description}
        {_bulletList}
      </div>
    </div>);
  } else if (mode === 'reverse') {
    return (<div id={customId} className="homepage-block homepage-block--default homepage-block--default-reverse"
                 style={{ background: hasBg }}>
      <div className="homepage-block__text">
        {_headline}
        {_title}
        {_description}
        {_bulletList}
      </div>
      {_image}
    </div>);
  } else if (mode === 'big') {
    return (
      <div id={customId} className="homepage-block homepage-block--big" style={{ background: hasBg }}>
        <div>
          {_title}
        </div>
        <div className="homepage-block--big__image-and-descriptions">
          <div>
            {_description1}
            {_description2}
          </div>
          {_image}
          <div>
            {_description3}
            {_description4}
            {_description5}
          </div>
        </div>
        {_imageMobile}
      </div>
    );
  } else if (mode === 'cta-handshakes') {
    return (
      <div id={customId} className="homepage-block homepage-block--cta-handshakes" style={{ background: hasBg }}>
        <div>
          {_title}
          {_description}
        </div>
        {_handshakeList}
        {_ctaSecondary}
      </div>
    );
  } else if (mode === 'partners') {
    return (
      <div id={customId} className="homepage-block homepage-block--partners" style={{ background: hasBg }}>
        <div>
          {_title}
          {/*{_description}*/}
        </div>
        {_partners}
      </div>
    );
  } else if (mode === 'quotes') {
    return (
      <div id={customId} className="homepage-block homepage-block--quotes">
        <div>
          {_title}
          {/*{_description}*/}
        </div>
        {_quotes}
      </div>
    );
  } else {
    return (<></>);
  }
};

export default HomepageBlock;

HomepageBlock.propTypes = {
  customId: PropTypes.any,
  mode: PropTypes.string.isRequired,
  headline: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.array,
  descriptions: PropTypes.array,
  bulletList: PropTypes.array,
  cta: PropTypes.any,
  images: PropTypes.any,
  hasBg: PropTypes.any.isRequired,
  isAgent: PropTypes.bool,
  quotes: PropTypes.any,
};
