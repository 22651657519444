import React       from 'react';
import './Footer.scss';
import logo        from '../../images/logo__grey.svg';
import logoTypo    from '../../images/footer/logo-typo-bw.svg';
import appleBtn     from '../../images/badge-apple_store.svg';
import googleBtn    from '../../images/badge-google_play.svg';
import facebook     from '../../images/footer/facebook.svg';
import twitter      from '../../images/footer/twitter.svg';
import linkedin     from '../../images/footer/linkedin.svg';
import eu           from '../../images/footer/EU.png';
import italy        from '../../images/footer/italia.png';
import fvg          from '../../images/footer/logo-fvg-footer.jpg';
import ivass        from '../../images/footer/ivass-marchio.jpg';
import { useIntl }  from "gatsby-plugin-intl";
import { Link }     from 'gatsby';
import { navigate } from '../../../.cache/gatsby-browser-entry';

const Footer = ({documents}) => {
  const intl = useIntl();

  const goToHomePage = () => {
    navigate('/');
  }

  return (
    <div className="footer">
      <div className="footer__header">
        <div>
          <img className="cursor-pointer" onClick={goToHomePage} src={logo} alt="bw logo"/>
        </div>
        <p>{intl.formatMessage({id: "footer.download-our-app"})}</p>
        <div>
          <img className="cursor-pointer" src={appleBtn} alt="apple btn" onClick={() => window.open('https://apps.apple.com/it/app/assibro/id1482279928', 'blank')}/>
          <img className="cursor-pointer" src={googleBtn} alt="google btn" onClick={() => window.open('https://play.google.com/store/apps/details?id=com.assibro.app', 'blank')}/>
        </div>
      </div>
      <div className="footer__content">
        <div className="footer__content__assibro">
          <h5>{intl.formatMessage({id: "footer.assibro"})}</h5>
          <div><Link to={'/'}>{intl.formatMessage({id: "footer.link-clients"})}</Link></div>
          <div><Link to={'/intermediari'}>{intl.formatMessage({id: "footer.link-agents"})}</Link></div>
          <div><Link to={'/chi-siamo'}>{intl.formatMessage({id: "footer.link-who"})}</Link></div>
          <div><Link to={'/contatti'}>{intl.formatMessage({id: "footer.link-contacts"})}</Link></div>
        </div>
        {/*<div className="footer__content__products">
          <h5>{intl.formatMessage({id: "footer.products"})}</h5>
          {!!categories && !!categories.length && categories.map((category, i) => (
            <div key={i}><Link to={`/categorie/${category._meta.uid}`}>{category.name}</Link></div>
          ))}
        </div>*/}
        <div className="footer__content__social">
          <h5>{intl.formatMessage({id: "footer.social"})}</h5>
          <div><a target="_blank" href="https://www.facebook.com/AssiBro.Broker"><img src={facebook} alt="facebook"/> {intl.formatMessage({id: "footer.link-facebook"})}</a></div>
          {/*<div><a><img src={twitter} alt="twitter"/> {intl.formatMessage({id: "footer.link-twitter"})}</a></div>*/}
          <div><a target="_blank" href="https://www.linkedin.com/company/assibro-broker-di-assicurazioni/"><img src={linkedin} alt="linkedin"/> {intl.formatMessage({id: "footer.link-linkedin"})}</a></div>
        </div>
        <div className="footer__content__other">
          <h5>{intl.formatMessage({id: "footer.info"})}</h5>
          <div><a href={documents.general_conditions.url} target="_blank">{intl.formatMessage({id: "footer.link-conditions"})}</a></div>
          <div><a href={documents.privacy_policy.url} target="_blank">{intl.formatMessage({id: "footer.link-privacy"})}</a></div>
          <div><a href={documents.attachment_3_4.url} target="_blank">{intl.formatMessage({id: "footer.link-attachment-3"})}</a></div>
          <div><a href={documents.electronic_signature.url} target="_blank">{intl.formatMessage({id: "footer.link-electronic-signature"})}</a></div>
        </div>
        <div className="footer__content__money-things">
          <small>{intl.formatMessage({id: "footer.money-things"})}</small>
          <small>{intl.formatMessage({id: "footer.money-things-2"})}</small>
        </div>
        <div className="footer__content__authorities">
          <div>
            <img src={ivass} alt="ivass"/>
          </div>
          <img src={eu} alt="eu"/>
          <img src={italy} alt="italy"/>
          <img src={fvg} alt="fvg"/>
        </div>
      </div>
      <div className="footer__credits">
        <p>Designed and developed by <a href="https://www.moku.io/" target="_blank">Moku srl</a></p>
      </div>
    </div>
  );
};

export default Footer;
