import React      from "react"
import PropTypes  from "prop-types"
import "styles/base.scss"
import "./Layout.scss"
import Footer     from "../Footer/Footer"
import Header     from '../Header/Header';
import { Helmet } from 'react-helmet/es/Helmet';
import favicon from '../../images/logo-tablet.png'

const Layout = ({ children, categories, documents }) => (
  <div>
    <Helmet>
      <link rel="icon" href={favicon} />
    </Helmet>
    <div className="layout">
      <Header categories={categories}/>
      <main className="layout__content">
        {children}
      </main>
      <Footer categories={categories} documents={documents}/>
    </div>
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  categories: PropTypes.array.isRequired
}

export default Layout
