import React, { useState } from 'react';
import './Header.scss';
import logo                from '../../images/logo-colored.png';
import logoTablet          from '../../images/logo-tablet.png';
import logoMobile          from '../../images/logo-mobile.svg';
import hamburger           from '../../images/hamburger-menu.svg';
import { Link, useIntl }   from 'gatsby-plugin-intl';
import MobileMenu          from '../_ui/MobileMenu/MobileMenu';
import PropTypes           from 'prop-types';
import { navigate }        from '../../../.cache/gatsby-browser-entry';
import scrollTo            from "gatsby-plugin-smoothscroll"

const Header = () => {
  const intl = useIntl();
  const [menuOpened, setMenuOpened] = useState(false);
  const [hoverMenuVisible, setHoverMenuVisible] = useState(false);

  const onOpenMenuClick = () => {
    setMenuOpened(true);
    window.scrollTo(0,0);
    document.body.style.overflowY = 'hidden';
  };

  const onCloseMenuClick = () => {
    setMenuOpened(false);
    document.body.style.overflowY = 'auto';
  };

  const toggleHoverMenu = () => {
    setHoverMenuVisible(!hoverMenuVisible);
  };

  const signIn = () => {
    window.open('https://my.assibro.it/sign-in', 'blank')
  }

  const signUp = () => {
    window.open('https://my.assibro.it/sign-up', 'blank');
  }

  const goToHomePage = () => {
    navigate('/');
  }

  return (
    <>
      <div className="header">
        <div className="header__hamburger" onClick={onOpenMenuClick}><img src={hamburger} alt="hamburger"/></div>
        <div className="header__logo">
          <img src={logo} alt="Assibro logo" className="header__logo__pc cursor-pointer" onClick={goToHomePage}/>
          <img src={logoTablet} alt="Assibro logo" className="header__logo__tablet cursor-pointer" onClick={goToHomePage}/>
          <img src={logo} alt="Assibro logo" className="header__logo__mobile cursor-pointer" onClick={goToHomePage}/>
        </div>
        <div className="header__nav">
          <Link to={'/'} className="header__nav__link" activeClassName="header__nav__link--active">
            <div>{intl.formatMessage({ id: 'header.nav-clients' })}</div>
          </Link>
          <Link to={'/intermediari'} className="header__nav__link" activeClassName="header__nav__link--active">
            <div>{intl.formatMessage({ id: 'header.nav-agents' })}</div>
          </Link>
          <div className="header__nav__link" onClick={() => {
            if(window.location.href.includes('contatti')){
              navigate('/');
              setTimeout(()=>{
                scrollTo('#products');
              }, 1000)
            } else {
              scrollTo('#products');
            }
          }}>
            <div>{intl.formatMessage({ id: 'header.nav-products' })}</div>
          </div>
          {/*
          <div className="header__nav__hover-menu">
            <div className="header__nav__link" onClick={toggleHoverMenu}>
              <div>{intl.formatMessage({ id: 'header.nav-products' })}</div>
            </div>
            <div className={hoverMenuVisible ? 'header__nav__categories header__nav__categories--visible' : 'header__nav__categories'}>
              {categories && categories.length && categories.map((category, i) => (
                <Link className="header__nav__categories__category" to={`/categorie/${category._meta.uid}`} key={i}>
                  <img src={category.icon.url} alt="home"/>
                  <p>{category.name}</p>
                </Link>
              ))}
            </div>
          </div>*/}
          <Link to={'/contatti'} className="header__nav__link" activeClassName="header__nav__link--active">
            <div>{intl.formatMessage({ id: 'header.nav-contacts' })}</div>
          </Link>
        </div>
        <div className="header__buttons">
          <button className="ab-button ab-button-secondary" onClick={signIn}>
            Accedi
          </button>
          <button className="ab-button" onClick={signUp}>
            Registrati gratis
          </button>
        </div>
      </div>
      {menuOpened && <MobileMenu closeMenu={onCloseMenuClick}/>}
    </>
  );
};

export default Header;


