import React        from 'react';
import './MobileMenu.scss';
import close        from '../../../images/close_icon.svg';
import logoGrey     from '../../../images/logo__grey.svg';
import agents       from '../../../images/mobile-agents.svg';
import clients      from '../../../images/mobile-clients.svg';
import { useIntl }  from "gatsby-plugin-intl";
import { Link }     from 'gatsby';
import { navigate } from '../../../../.cache/gatsby-browser-entry';

const MobileMenu = (props) => {
  const intl = useIntl();

  const goTo = (url) => {
    window.open(url, 'blank')
  }

  const goToHomePage = () => {
    props.closeMenu();
    navigate('/');
  }

  return (
    <div className="mobile-menu">
      <div className="mobile-menu__close" onClick={props.closeMenu}>
        <img className="cursor-pointer" src={close} alt="close"/>
      </div>
      <div className="mobile-menu__logo">
        <img className="cursor-pointer" src={logoGrey} alt="assibro grey" onClick={goToHomePage}/>
      </div>
      <div className="mobile-menu__content">
        <div className="mobile-menu__primary-links">
          <Link to={'/'} onClick={props.closeMenu}>
            <p>{intl.formatMessage({id: "mobile-menu.clients"})}</p>
          </Link>
          <Link to={'/chi-siamo'} onClick={props.closeMenu}>
            <p>{intl.formatMessage({id: "mobile-menu.who-we-are"})}</p>
          </Link>
          <Link to={'/intermediari'} onClick={props.closeMenu}>
            <p>{intl.formatMessage({id: "mobile-menu.agents"})}</p>
          </Link>
          <Link to={'/contatti'} onClick={props.closeMenu}>
            <p>{intl.formatMessage({id: "mobile-menu.contacts"})}</p>
          </Link>
        </div>
        <div className="mobile-menu__divider mobile-menu__divider--divider-1"/>
        {/*<div className="mobile-menu__products">
          <h4>{intl.formatMessage({id: "mobile-menu.products"})}</h4>
          {!!props.categories && !!props.categories.length && props.categories.map((category, i) => (
            <div key={i} onClick={props.closeMenu}><Link to={`/categorie/${category._meta.uid}`}><img src={category.icon.url} alt={category.icon.alt}/> {category.name}</Link></div>
          ))}
        </div>*/}
        <button className="mobile-menu__log-in" onClick={() => goTo('https://my.assibro.it/sign-in')}>{intl.formatMessage({id: "mobile-menu.log-in"})}</button>
        <button className="mobile-menu__sign-up" onClick={() => goTo('https://my.assibro.it/sign-up')}>{intl.formatMessage({id: "mobile-menu.sign-up"})}</button>
      </div>
    </div>
  );
};

export default MobileMenu;
