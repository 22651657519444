import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import './Carousel.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider               from 'react-slick';
import doubleQuote          from '../../../images/quote.svg';

export default class Carousel extends Component {

  render() {
    var settings = {
      dots: true,
      infinite: false,
      slidesToShow: 2,
      slidesToScroll: 1,
      responsive: [{
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      ],
    };
    const quotes = this.props.quotes;
    return (
      quotes.length &&
      <Slider {...settings}>
        {quotes.map((q, i) => (
          <div className="quote-slide-container" key={i}>
            <div className="quote">
              <img src={doubleQuote} alt="double quote"/>
              <div className="quote__body">
                <p>{q.text}</p>
              </div>
              <div className="quote__footer">
                <div style={{ backgroundImage: `url(${q.author.profileImage})` }}/>
                <div>
                  <b>{q.author.name}</b>
                  <p>{q.author.role}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    );
  }
}

Carousel.propTypes = {
  quotes: PropTypes.any.isRequired,
};
